import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from 'client/components/Header';
import ExchangeReadout from 'client/components/ExchangeReadout';
import { faBook } from '@fortawesome/free-solid-svg-icons';
const Conversation = () => {
    const [tablet, setTablet] = useState('');
    let { id } = useParams();
    const [key, setKey] = useState(0);
    // Use useEffect to force rerender when the ID changes
    useEffect(() => {
        // Convert the ID to a number (or use it directly if it's a string)
        // to ensure it's unique and use it as the key to force rerender
        setKey(Number(id));
    }, [id]);
    useEffect(() => {
        axios.get('/api/tablet/conversation/' + id)
            .then(res => {
            console.log(res);
            const newTablet = res.data.map((exchange, index) => {
                return (React.createElement("div", { className: 'mb-3', key: index },
                    React.createElement(ExchangeReadout, { exchange: exchange })));
            });
            setTablet(newTablet);
        });
    }, [id]);
    return (React.createElement("div", { className: 'container px-0', style: { 'fontFamily': 'Inter' } },
        React.createElement(Header, { title: 'Tablet', icon: faBook }),
        React.createElement("div", { className: 'd-flex flex-column px-3' }, tablet)));
};
export { Conversation };
