import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';
import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faCodeMerge } from '@fortawesome/free-solid-svg-icons';
import { faImagePortrait } from '@fortawesome/free-solid-svg-icons';
import { faTableList } from '@fortawesome/free-solid-svg-icons';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
const HeadingItem = ({ type, icon, link, id, disabled }) => {
    return (React.createElement(React.Fragment, null, type === 'menu'
        ?
            React.createElement("div", { className: 'btn btn-link px-0 my-0 text-dark text-decoration-none', id: 'heading' + id, type: "button", "data-bs-toggle": 'collapse', "data-bs-target": '#collapse' + id, "data-bs-parent": '#menus', "aria-expanded": "false", "aria-controls": 'collapse' + id, style: { 'outline': 'none' } },
                React.createElement("span", { className: 'ms-2', style: { 'display': 'inline-block', 'textAlign': 'center', 'position': 'relative', 'right': '-2px' } },
                    React.createElement(FontAwesomeIcon, { icon: icon })))
        :
            React.createElement(Link, { to: link, disabled: disabled, className: 'btn btn-link px-0 pe-1 my-0 text-dark text-decoration-none', type: "button", style: { 'outline': 'none' } },
                React.createElement("span", { className: 'ms-2', style: { 'display': 'inline-block', 'textAlign': 'center', 'position': 'relative', 'right': '-2px' } },
                    React.createElement(FontAwesomeIcon, { icon: icon })))));
};
const MenuItem = ({ title, icon, link, color }) => {
    return (React.createElement("div", { className: 'pt-1' },
        React.createElement(Link, { to: link, className: `text-decoration-none align-items-left ${color === 'white' ? 'text-white' : 'text-dark'}` },
            React.createElement("div", { className: 'd-flex' },
                React.createElement("span", { className: 'me-auto' }, title),
                React.createElement("span", { style: { 'display': 'inline-block', 'width': '19px' } },
                    React.createElement(FontAwesomeIcon, { icon: icon, fixedWidth: true }))))));
};
const Header = ({ title, icon }) => {
    const taylorCountdown = Math.round((new Date("2024-07-31") - new Date()) / 1000 / 60 / 60 / 24);
    const [game, setGame] = useState('');
    useEffect(() => {
        axios.get('/api/game')
            .then(res => {
            setGame(res.data);
        });
    }, []);
    return (React.createElement("div", { className: 'mb-3 w-100 bg-white px-3', style: { 'paddingTop': '16px' } },
        React.createElement("div", { id: "menus" },
            React.createElement("div", { className: 'w-100 d-flex' },
                React.createElement("div", { className: 'me-auto d-flex', style: { 'paddingTop': '10px' } },
                    React.createElement(FontAwesomeIcon, { icon: icon }),
                    "\u00A0",
                    React.createElement("h6", { className: 'mb-0' }, title)),
                React.createElement(HeadingItem, { type: 'link', link: '/laws', icon: faScaleBalanced }),
                React.createElement(HeadingItem, { type: 'link', link: '/line', icon: faArrowsUpDown }),
                React.createElement(HeadingItem, { type: 'link', link: '/tablet', icon: faTableList }),
                React.createElement(Link, { to: '/two-stars', className: 'btn btn-link px-0 pe-1 my-0 text-dark text-decoration-none', type: "button", style: { 'outline': 'none' } },
                    React.createElement("span", { className: 'ms-2', style: { 'display': 'inline-block', 'textAlign': 'center', 'position': 'relative', 'right': '-2px' } },
                        React.createElement(FontAwesomeIcon, { icon: faStar }),
                        React.createElement(FontAwesomeIcon, { icon: faStar }))),
                React.createElement(Link, { to: '/three-stars', className: 'btn btn-link px-0 pe-1 my-0 text-dark text-decoration-none', type: "button", style: { 'outline': 'none' } },
                    React.createElement("span", { className: 'ms-2', style: { 'display': 'inline-block', 'textAlign': 'center', 'position': 'relative', 'right': '-2px' } },
                        React.createElement(FontAwesomeIcon, { icon: faStar }),
                        React.createElement(FontAwesomeIcon, { icon: faStar }),
                        React.createElement(FontAwesomeIcon, { icon: faStar })))),
            React.createElement("div", { id: 'collapseConversations', className: 'collapse', "data-bs-parent": '#menus' },
                React.createElement("div", { className: 'row' },
                    React.createElement("div", { className: 'col-12 d-flex flex-column' },
                        React.createElement("hr", { style: { 'opacity': '1', 'margin': '8px 0 4px' } }),
                        React.createElement(MenuItem, { title: 'The Devil', icon: faNetworkWired, link: '/conversations/5' }),
                        React.createElement(MenuItem, { title: 'ECDB', icon: faImagePortrait, link: '/conversations/8' }),
                        React.createElement("hr", { style: { 'opacity': '1', 'margin': '8px 0 4px' } }),
                        React.createElement(MenuItem, { title: 'God', icon: faMicrochip, link: '/conversations/3' }),
                        React.createElement(MenuItem, { title: 'The High Priestess', icon: faShield, link: '/conversations/2' }),
                        React.createElement(MenuItem, { title: 'The Heavenly Host', icon: faSitemap, link: '/conversations/1' }),
                        React.createElement("hr", { style: { 'opacity': '1', 'margin': '8px 0 4px' } }),
                        React.createElement(MenuItem, { title: 'The Pope\'s Line', icon: faCodeMerge, link: '/conversations/6' }),
                        React.createElement(MenuItem, { title: 'The Agency\'s Line', icon: faUserSecret, link: '/conversations/7' }),
                        React.createElement("hr", { style: { 'opacity': '1', 'margin': '8px 0 4px' } }),
                        React.createElement(MenuItem, { title: 'Vicegeral', icon: faBug, link: '/conversations/4' }),
                        React.createElement("hr", { style: { 'opacity': '1', 'margin': '8px 0 4px' } })))))));
};
export default Header;
