import React, { useEffect, useState } from 'react';
import 'client/App.css';
import 'client/locations/Sign.css';
const ridgesSpecification = [
    ['adamant', '2022-02-01', '2022-04-30'],
    ['beta', '2022-05-01', '2022-07-31'],
    ['gamma', '2022-08-01', '2022-10-31'],
    ['delta', '2022-11-01', '2023-01-31'],
    ['epsilon', '2023-02-01', '2023-04-30'],
    ['zeta', '2023-05-01', '2023-07-31'],
    ['eta', '2023-08-01', '2023-10-31'],
    ['theta', '2023-11-01', '2024-01-31'],
    ['iota', '2024-02-01', '2024-04-30'],
    ['kappa', '2024-05-01', '2024-07-31'],
    ['lambda', '2024-08-01', '2024-10-31'],
    ['mu', '2024-11-01', '2025-01-31'],
    ['nu', '2025-02-01', '2025-04-30'],
    ['xi', '2025-05-01', '2025-07-31'],
    ['omicron', '2025-08-01', '2025-10-31'],
    ['pi', '2025-11-01', '2026-01-31'],
    ['rho', '2026-02-01', '2026-04-30'],
    ['sigma', '2026-05-01', '2026-07-31'],
    ['tau', '2026-08-01', '2026-10-31'],
    ['upsilon', '2026-11-01', '2027-01-31'],
    ['phi', '2027-02-01', '2027-04-30'],
    ['chi', '2027-05-01', '2027-07-31'],
    ['pi', '2027-08-01', '2027-10-31'],
    ['psi', '2027-11-01', '2028-01-31'],
    ['omega', '2028-02-01', '2028-04-30'],
];
const Sign = () => {
    const [ridges, setRidges] = useState(React.createElement(React.Fragment, null));
    useEffect(() => {
        const renderedRidges = ridgesSpecification.map((ridgeSpec, i) => {
            const ridgeStart = new Date(ridgeSpec[1]);
            const ridgeEnd = new Date(ridgeSpec[2]);
            const today = new Date();
            let ridgeProgress = React.createElement(React.Fragment, null);
            if (ridgeEnd < today) {
                ridgeProgress = React.createElement("div", { className: 'complete' });
            }
            else if (ridgeStart < today) {
                let daysInRidge = (ridgeEnd - ridgeStart) / 86400000;
                let percentageComplete = (today - ridgeStart) / (ridgeEnd - ridgeStart) * 222;
                ridgeProgress = React.createElement("div", { className: 'complete', style: { 'width': percentageComplete + 'px' } });
            }
            return (React.createElement("div", { key: i, className: `${ridgeSpec[0]} ridge d-flex mb-1` }, ridgeProgress));
        });
        setRidges(renderedRidges);
    }, []);
    return (React.createElement("div", { className: 'container px-4', style: { 'paddingTop': '33px', 'fontFamily': 'Inter' } },
        React.createElement("h3", { style: { 'marginBottom': '33px' } }, "Octary"),
        React.createElement("p", null, "We believe in The Good Century."),
        React.createElement("p", null, "Today, the 8 billion people of Earth produce $80 trillion in value, enough for each person on earth to live a dignified life."),
        React.createElement("p", { style: { 'marginBottom': '33px' } }, "We work to that end."),
        ridges,
        React.createElement("p", { style: { 'marginTop': '38px' } },
            React.createElement("a", { href: '/gate', className: 'text-decoration-none' }, "Gate \u2192")),
        React.createElement("div", { style: { 'marginTop': '33px', 'marginBottom': '22px', 'fontFamily': 'Monospace', 'fontSize': '12px' } }, "4-20-2024: KoP")));
};
export { Sign };
