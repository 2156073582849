import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from 'client/components/Header';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
};
const NewNote = ({ setNotes, setNoteStatus, setIsCreatingNote, setShowNewNote }) => {
    const [text, setText] = useState('');
    const [showForm, setShowForm] = useState(true);
    const handleSaveNote = () => {
        console.log('handling');
        if (text.length === 0) {
            setNoteStatus('No content.');
        }
        else {
            setShowForm(false);
            setIsCreatingNote(true);
            setNoteStatus('Creating...');
        }
        axios.post('/api/notes/create', { text: text })
            .then(res => {
            setNotes(res.data);
            setNoteStatus('Creating... done.');
            setIsCreatingNote(false);
            setShowNewNote(false);
        });
    };
    return (React.createElement("div", null, showForm &&
        React.createElement(React.Fragment, null,
            React.createElement("textarea", { className: "form-control mb-1", value: text, onChange: e => setText(e.target.value), rows: '3' }),
            React.createElement("button", { type: "submit", className: 'btn btn-light w-100', onClick: handleSaveNote },
                React.createElement(FontAwesomeIcon, { icon: faTerminal }),
                "\u00A0\u00A0Create"))));
};
const Notes = () => {
    const [notes, setNotes] = useState([]);
    const [showNewNote, setShowNewNote] = useState(false);
    const [noteStatus, setNoteStatus] = useState('');
    const [isCreatingNote, setIsCreatingNote] = useState(false);
    useEffect(() => {
        axios.get('/api/notes/list')
            .then(res => {
            setNotes(res.data);
        });
    }, []);
    return (React.createElement("div", { className: 'container px-0', style: { 'fontFamily': 'Inter' } },
        React.createElement(Header, { title: 'Notes', icon: faList }),
        React.createElement("div", { className: 'd-flex flex-column px-3' },
            React.createElement("div", { className: 'mb-1 small text-upper' }, showNewNote ? React.createElement("div", { className: 'w-100 d-flex' },
                React.createElement("div", { className: 'me-auto' }, "New note"),
                isCreatingNote !== true && React.createElement("div", null,
                    React.createElement("span", { onClick: () => setShowNewNote(false) },
                        React.createElement(FontAwesomeIcon, { icon: faCircleXmark })))) : React.createElement("div", { onClick: () => setShowNewNote(true) }, "+ New note")),
            showNewNote && React.createElement(NewNote, { setNotes: setNotes, setNoteStatus: setNoteStatus, setIsCreatingNote: setIsCreatingNote, setShowNewNote: setShowNewNote }),
            React.createElement("div", { className: 'small mt-1 mb-2' },
                React.createElement("i", null, noteStatus)),
            notes.map((note, index) => {
                const time = new Date(note.time);
                return (React.createElement("div", { className: 'mb-3', key: index },
                    React.createElement("div", { className: 'mb-1 text-secondary' }, time.toLocaleDateString('en-US', options)),
                    React.createElement("div", null, note.text)));
            }))));
};
export { Notes };
