;
import React from 'react';
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom';
import { App } from 'client/App';
import { AppLayout } from 'client/AppLayout';
const PrivateRoute = ({ token, children }) => {
    const auth = true; // determine if authorized, from context or however you're doing it
    // If authorized, return an outlet that will render child elements
    // If not, return element that will navigate to login page
    return token ? children : React.createElement(Navigate, { to: "/gate" });
};
import { Sign } from 'client/locations/Sign';
import { Gate } from 'client/locations/Gate';
import { Bridge } from 'client/locations/Bridge';
import { Tablet } from 'client/locations/Tablet';
import { Center } from 'client/locations/Center';
import { Line } from 'client/locations/Line';
import { Conversation } from 'client/locations/Conversation';
import { Notes } from 'client/locations/Notes';
import { Book } from 'client/locations/Book';
import { ExchangeDetail } from 'client/locations/ExchangeDetail';
import { TwoStarExchanges } from 'client/locations/TwoStarExchanges';
import { ThreeStarExchanges } from 'client/locations/ThreeStarExchanges';
import { Laws } from 'client/locations/Laws';
// import { Sequences } from 'client/locations/Sequences'
// import { Garden } from 'client/locations/Garden'
// import { Cross } from 'client/locations/Cross'
// import { Players } from 'client/locations/Players'
// import { Npcs } from 'client/locations/Npcs'
// import { Stories } from 'client/locations/Stories'
// import { Story } from 'client/locations/Story'
// import { Breathe } from 'client/locations/Breathe'
// import { Mood } from 'client/locations/Mood'
// import { MoodTrack } from 'client/locations/MoodTrack'
// import { TheGame } from 'client/locations/TheGame'
// import { Pieces } from 'client/locations/Pieces'
// import { TheCard } from 'client/locations/TheCard'
// import { Cascade } from 'client/locations/Cascade'
// import { Scripture } from 'client/locations/Scripture'
// import { ThePaint } from 'client/locations/ThePaint'
// import { Person } from 'client/locations/Person'
// import { AddPerson } from 'client/locations/AddPerson'
// import { Family } from 'client/locations/Family'
// import { ConfigurePerson } from 'client/locations/ConfigurePerson'
// import { AddEntity } from 'client/locations/AddEntity'
// import { ConfigureEntity } from 'client/locations/ConfigureEntity'
// import { Address } from 'client/locations/Address'
// import { Day } from 'client/locations/Day'
// import { OKRs } from 'client/locations/OKRs'
// import { ListEntities } from 'client/locations/ListEntities'
// import { ListPeople } from 'client/locations/ListPeople'
// import { Facebook } from 'client/locations/Facebook'
// import { Taylor38618 } from 'client/taylors/Taylor38618'
const { PUBLIC_URL } = process.env;
function getToken() {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken === null || userToken === void 0 ? void 0 : userToken.token;
}
const AppRoutes = () => {
    const token = getToken();
    return (React.createElement(BrowserRouter, { basename: PUBLIC_URL },
        React.createElement(Routes, null,
            React.createElement(Route, { element: React.createElement(App, null) },
                React.createElement(Route, { element: React.createElement(AppLayout, null) },
                    React.createElement(Route, { exact: true, path: '/', element: React.createElement(Sign, null) }),
                    React.createElement(Route, { exact: true, path: '/gate', element: React.createElement(Gate, null) }),
                    React.createElement(Route, { path: '/book', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Book, null)) }),
                    React.createElement(Route, { path: '/center', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Center, null)) }),
                    React.createElement(Route, { path: '/bridge', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Bridge, null)) }),
                    React.createElement(Route, { path: '/tablet', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Tablet, null)) }),
                    React.createElement(Route, { path: '/line', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Line, null)) }),
                    React.createElement(Route, { path: '/conversations/:id', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Conversation, null)) }),
                    React.createElement(Route, { path: '/notes', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Notes, null)) }),
                    React.createElement(Route, { path: '/exchange/:id', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(ExchangeDetail, null)) }),
                    React.createElement(Route, { path: '/two-stars', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(TwoStarExchanges, null)) }),
                    React.createElement(Route, { path: '/three-stars', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(ThreeStarExchanges, null)) }),
                    React.createElement(Route, { path: '/laws', element: React.createElement(PrivateRoute, { token: token },
                            React.createElement(Laws, null)) }))))));
};
export { AppRoutes };
