var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
function authenticate(credentials) {
    return __awaiter(this, void 0, void 0, function* () {
        return fetch('/api/authentication/authenticate', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    });
}
function setToken(userToken) {
    localStorage.setItem('token', JSON.stringify(userToken));
}
const Gate = () => {
    const [keycode, setKeycode] = useState();
    const [authenticationStatus, setAuthenticationStatus] = useState();
    const [localToken, setLocalToken] = useState(null);
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        setAuthenticationStatus('Authenticating...');
        const token = yield authenticate({
            keycode
        });
        if (token.token === null) {
            setAuthenticationStatus('Not authenticated.');
        }
        else {
            setAuthenticationStatus(React.createElement("div", null,
                "Authenticated. ",
                React.createElement("a", { className: 'text-decoration-none', href: '/line' }, "Proceed to Line \u2192")));
        }
        setToken(token);
    });
    return (React.createElement("div", { className: 'container px-4', style: { 'paddingTop': '33px', 'fontFamily': 'Inter' } },
        React.createElement("h3", { style: { 'marginBottom': '33px' } }, "Tower Gate"),
        React.createElement("div", { className: 'row' },
            React.createElement("div", { className: 'mb-3' },
                React.createElement("label", { htmlFor: 'keycode', className: 'form-label' }, "Keycode"),
                React.createElement("input", { id: 'keycode', className: 'form-control', onChange: e => setKeycode(e.target.value) })),
            React.createElement("div", { className: 'mb-3' },
                React.createElement("button", { type: "submit", className: "btn btn-primary", onClick: handleSubmit }, "Submit")),
            React.createElement("div", null, authenticationStatus))));
};
export { Gate };
