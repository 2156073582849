import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faSitemap } from '@fortawesome/free-solid-svg-icons';
import { faShield } from '@fortawesome/free-solid-svg-icons';
import { faNetworkWired } from '@fortawesome/free-solid-svg-icons';
import { faBug } from '@fortawesome/free-solid-svg-icons';
import { faMicrochip } from '@fortawesome/free-solid-svg-icons';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { faCodeMerge } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const recipients = {
    1: faSitemap,
    2: faShield,
    3: faMicrochip,
    4: faBug,
    5: faNetworkWired,
    6: faCodeMerge,
    7: faUserSecret
};
const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
};
const ExchangeReadout = ({ exchange }) => {
    const [response, setResponse] = useState(React.createElement(React.Fragment, null));
    const [responseNumbers, setResponseNumbers] = useState(React.createElement(React.Fragment, null));
    const time = new Date(exchange.time);
    useEffect(() => {
        let responseIndex = exchange.response.indexOf('|');
        setResponse(exchange.response.substring(0, responseIndex).trim());
        setResponseNumbers(exchange.response.substring(responseIndex).trim().replace('|', '').replace(/,/g, ' '));
    }, [exchange]);
    return (React.createElement(Link, { to: '/exchange/' + exchange.id, style: { textDecoration: 'none', color: 'inherit' } },
        React.createElement("div", { key: exchange.id },
            React.createElement("div", { className: "small mb-1" },
                React.createElement("span", { onClick: (e) => handleCoding(exchange, e), style: { "position": "relative", "top": "-1px", "border": "1px solid black", }, className: "badge text-dark bg-none" }, exchange.id),
                exchange.recipient && React.createElement(React.Fragment, null,
                    "\u00A0\u00A0",
                    React.createElement(FontAwesomeIcon, { icon: recipients[exchange.recipient] })),
                "\u00A0\u00A0",
                time.toLocaleDateString('en-US', options)),
            React.createElement("div", { className: "small mb-0", style: { 'fontWeight': '500' } },
                exchange.transmission,
                " \u2192"),
            React.createElement("div", { className: 'small mb-0 text-success mb-0 text-weight-bold', style: { 'fontWeight': '500' } }, response),
            React.createElement("div", { className: 'text-success', style: { 'fontFamily': 'monospace', 'fontSize': '12px' } },
                React.createElement("div", null, responseNumbers)),
            exchange.definition && React.createElement("div", { className: "small mt-1 text-secondary" },
                React.createElement("i", null, "Definition:"),
                " ",
                exchange.definition),
            exchange.interpretation && React.createElement("div", { className: "small mt-1 text-secondary" },
                React.createElement("i", null, "Interpretation:"),
                " ",
                exchange.interpretation))));
};
export default ExchangeReadout;
