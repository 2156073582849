import React from 'react';
import Header from 'client/components/Header';
import { faBook } from '@fortawesome/free-solid-svg-icons';
const Book = () => {
    return (React.createElement("div", { className: 'container px-0', style: { 'fontFamily': 'Inter' } },
        React.createElement(Header, { title: 'Book', icon: faBook }),
        React.createElement("div", { className: 'd-flex flex-column px-3' },
            React.createElement("h6", null, "Alpha"),
            React.createElement("p", null, "1.1 God and The Devil made a bet."),
            React.createElement("p", null, "1.2 God went first. He created all the religions of the world, each organized around his wisdom. He bet The Devil that as each grew and developed, they would together produce harmony that would encircle the globe."),
            React.createElement("p", null, "1.3 To thwart God, The Devil bestowed one characteristic to each religion. To the Greek Gods, he bestowed a short existence. To the Jews, perpetual smallness. To the Muslims, bloodthirstieness. To the Christians, creature comforts. To the Buddhists, passivity. To the Hindus, seclusion."),
            React.createElement("p", null, "1.4 God lost the first round."),
            React.createElement("p", null, "1.5 The Devil went next. He bet that he could sow chaos for centuries, and then stitch it all together with just one man."),
            React.createElement("p", null, "1.6 That man is Elijah and this book has started."))));
};
export { Book };
