import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from 'client/components/Header';
import { faRoute } from '@fortawesome/free-solid-svg-icons';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { faHandsPraying } from '@fortawesome/free-solid-svg-icons';
import { faArrowsUpDown } from '@fortawesome/free-solid-svg-icons';
import { faCloudArrowDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
const Line = () => {
    const [transmission, setTransmission] = useState('');
    const [receivedResponse, setReceivedResponse] = useState(false);
    const [sentInterpretationAndDefinition, setSentInterpretationAndDefinition] = useState(false);
    const [time, setTime] = useState(null);
    const [response, setResponse] = useState(null);
    const [responseNumbers, setResponseNumbers] = useState(null);
    const [interpretation, setInterpretation] = useState(null);
    const [definition, setDefinition] = useState(null);
    const [lineStatus, setLineStatus] = useState('Transmit');
    const [interpretationAndDefinitionStatus, setInterpretationAndDefinitionStatus] = useState('Send');
    const [responseId, setResponseId] = useState(null);
    const [topicOptions, setTopicOptions] = useState([]);
    const [recipientOptions, setRecipientOptions] = useState([]);
    const [topic, setTopic] = useState('');
    const [recipient, setRecipient] = useState('');
    const [topicName, setTopicName] = useState(null);
    const [showRecipientMissing, setShowRecipientMissing] = useState(false);
    const [recipientName, setRecipientName] = useState(null);
    useEffect(() => {
        axios.get('/api/line/topicsAndRecipients')
            .then(res => {
            console.log(res.data);
            const newTopicOptions = res.data.topics.map((topic, index) => (React.createElement("option", { key: index, value: topic.id }, topic.topic)));
            const newRecipientOptions = res.data.recipients.map((recipient, index) => (React.createElement("option", { key: index, value: recipient.id }, recipient.recipient)));
            setTopicOptions(newTopicOptions);
            setRecipientOptions(newRecipientOptions);
        });
    }, []);
    const handlePray = () => {
        setTransmission('Praying...');
    };
    const handleSteer = () => {
        setTransmission('Requesting steer...');
    };
    const handleGenerate = () => {
        setTransmission('...');
    };
    useEffect(() => {
        if (transmission == 'Requesting steer...' || transmission == 'Praying...' || transmission == '...') {
            handleExchange();
        }
    }, [transmission]);
    const handleExchange = () => {
        console.log('handling');
        setLineStatus('Exchanging...');
        if (recipient !== '') {
            axios.post('/api/line/exchange', { transmission: transmission, who: recipientName, topic: topic, recipient: recipient })
                .then(res => {
                setTime(res.data.time);
                setReceivedResponse(true);
                let responseIndex = res.data.response.indexOf('|');
                setResponse(res.data.response.substring(0, responseIndex).trim());
                setResponseNumbers(React.createElement("div", null, res.data.response.substring(responseIndex).trim().replace('|', '').replace(/,/g, ' ')));
                if (res.data.exchange_recipient) {
                    setRecipientName(res.data.exchange_recipient.recipient);
                }
                if (res.data.exchange_topic) {
                    setTopicName(res.data.exchange_topic.topic);
                }
                setLineStatus('Exchanged');
                setResponseId(res.data.id);
                console.log('here');
                console.log(res.data);
            });
        }
        else {
        }
    };
    const handleInterpretationAndDefinition = () => {
        console.log('handling');
        setInterpretationAndDefinitionStatus('Sending...');
        axios.post('/api/line/setInterpretationAndDefinition', { interpretation: interpretation, definition: definition, id: responseId })
            .then(res => {
            setSentInterpretationAndDefinition(true);
        });
    };
    return (React.createElement("div", { className: 'container px-0' },
        React.createElement(Header, { title: 'Line', icon: faArrowsUpDown }),
        React.createElement("div", { className: 'd-flex flex-column px-3', style: { 'height': '563px' } },
            React.createElement("div", { style: { 'paddingBottom': (sentInterpretationAndDefinition && receivedResponse) ? '' : '226px' } },
                React.createElement("div", { className: 'card border-secondary mb-3' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement(React.Fragment, null,
                            !receivedResponse &&
                                React.createElement(React.Fragment, null,
                                    React.createElement("h5", null, "Transmission"),
                                    React.createElement("textarea", { className: "form-control", value: transmission, onChange: e => setTransmission(e.target.value), rows: '3' }),
                                    React.createElement("div", { className: 'd-flex mt-2' },
                                        React.createElement("select", { className: 'form-select', value: topic, onChange: (e) => setTopic(e.target.value) },
                                            React.createElement("option", { value: '' }, "Topic"),
                                            topicOptions)),
                                    React.createElement("div", { className: 'd-flex mt-2' },
                                        React.createElement("select", { className: 'form-select', value: recipient, onChange: (e) => setRecipient(e.target.value) },
                                            React.createElement("option", { value: '' }, "Recipient"),
                                            recipientOptions)),
                                    React.createElement("div", { className: 'd-flex mt-3' },
                                        React.createElement("div", { className: 'w-50 pe-1' },
                                            React.createElement("button", { type: "submit", className: 'btn btn-light w-100', onClick: handleExchange },
                                                React.createElement(FontAwesomeIcon, { icon: faPaperPlane }),
                                                "\u00A0\u00A0Transmit")),
                                        React.createElement("div", { className: 'w-50 ps-1' },
                                            React.createElement("button", { type: "submit", className: 'btn btn-light w-100', onClick: handleSteer },
                                                React.createElement(FontAwesomeIcon, { icon: faRoute }),
                                                "\u00A0\u00A0Steer"))),
                                    React.createElement("div", { className: 'd-flex mt-2' },
                                        React.createElement("div", { className: 'w-50 pe-1' },
                                            React.createElement("button", { type: "submit", className: 'btn btn-light w-100', onClick: handlePray },
                                                React.createElement(FontAwesomeIcon, { icon: faHandsPraying }),
                                                "\u00A0\u00A0Pray")),
                                        React.createElement("div", { className: 'w-50 pe-1' },
                                            React.createElement("button", { type: "submit", className: 'btn btn-light w-100', onClick: handleGenerate },
                                                React.createElement(FontAwesomeIcon, { icon: faCloudArrowDown }),
                                                "\u00A0\u00A0Generate")))),
                            receivedResponse &&
                                React.createElement(React.Fragment, null,
                                    React.createElement("h5", null, "Transmission"),
                                    React.createElement("p", { className: 'mb-0' }, transmission))))),
                receivedResponse &&
                    React.createElement(React.Fragment, null,
                        React.createElement("div", { className: 'card border-success' },
                            React.createElement("div", { className: 'card-body' },
                                React.createElement("h5", null, "Response"),
                                React.createElement("p", { className: 'mb-0 text-success mb-2' }, response),
                                React.createElement("div", { className: 'text-success', style: { 'fontFamily': 'monospace', 'fontSize': '12px' } },
                                    React.createElement("div", { className: 'text-muted' }, "1 2 3 4 5 6 7 8 9 0"),
                                    React.createElement("div", null, responseNumbers)),
                                interpretation !== null &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("p", { className: 'mb-0 mt-2 text-muted', style: { 'fontWeight': 500 } }, "Interpretation"),
                                        React.createElement("p", { className: 'mb-0 text-muted' },
                                            React.createElement("i", null, interpretation))),
                                definition !== null &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("p", { className: 'mb-0 mt-2 text-muted', style: { 'fontWeight': 500 } }, "Definition"),
                                        React.createElement("p", { className: 'mb-0 text-muted' },
                                            React.createElement("i", null, definition))),
                                topicName !== null &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("p", { className: 'mb-0 mt-2 text-muted', style: { 'fontWeight': 500 } }, "Topic"),
                                        React.createElement("p", { className: 'mb-0 text-muted' },
                                            React.createElement("i", null, topicName))),
                                recipientName !== null &&
                                    React.createElement(React.Fragment, null,
                                        React.createElement("p", { className: 'mb-0 mt-2 text-muted', style: { 'fontWeight': 500 } }, "Recipient"),
                                        React.createElement("p", { className: 'mb-0 text-muted' },
                                            React.createElement("i", null, recipientName))))),
                        React.createElement("div", { className: 'd-flex align-items-center' },
                            React.createElement("a", { href: 'https://en.wiktionary.org/wiki/' + response, target: '_BLANK', className: 'text-secondary mt-2', style: { color: 'inherit', textDecoration: 'none' } }, "Wiktionary definition")))),
            (receivedResponse && transmission !== 'Pinging control...') &&
                React.createElement(React.Fragment, null, sentInterpretationAndDefinition
                    ? React.createElement("div", { className: 'mt-auto pb-4' }, "Interpretation sent.")
                    : React.createElement("div", { className: 'p-3', style: { 'position': 'fixed', 'bottom': '0', 'left': '0', 'width': '100%', 'backgroundColor': 'white' } },
                        React.createElement("textarea", { className: "form-control mb-3", id: "definition", placeholder: 'Definition...', rows: "2", value: definition, onChange: e => setDefinition(e.target.value) }),
                        React.createElement("textarea", { className: "form-control mb-3", id: "interpretation", placeholder: 'Interpretation...', rows: "2", value: interpretation, onChange: e => setInterpretation(e.target.value) }),
                        React.createElement("button", { type: 'button', className: 'btn btn-light', onClick: handleInterpretationAndDefinition }, interpretationAndDefinitionStatus))))));
};
export { Line };
