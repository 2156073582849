var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Header from 'client/components/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
const ExchangeDetail = () => {
    const [exchange, setExchange] = useState(null);
    const { id } = useParams();
    const [showInterpretation, setShowInterpretation] = useState(false);
    const [sentInterpretation, setSentInterpretation] = useState(null);
    const [interpretation, setInterpretation] = useState(null);
    const [interpretationStatus, setInterpretationStatus] = useState('Send');
    const [showDefinition, setShowDefinition] = useState(false);
    const [sentDefinition, setSentDefinition] = useState(null);
    const [definition, setDefinition] = useState(null);
    const [definitionStatus, setDefinitionStatus] = useState('Send');
    const [starRating, setStarRating] = useState(null);
    // Fetch exchange data
    useEffect(() => {
        const fetchExchange = () => __awaiter(void 0, void 0, void 0, function* () {
            try {
                const response = yield axios.get(`/api/line/exchange/${id}`);
                let responseData = response.data;
                const responseText = responseData.response;
                let responseIndex = responseData.response.indexOf('|');
                console.log(responseText.substring(responseIndex).trim().replace('|', '').replace(/,/g, ' '));
                responseData.response = React.createElement(React.Fragment, null, responseText.substring(0, responseIndex).trim());
                responseData.responseNumbers = React.createElement(React.Fragment, null, responseText.substring(responseIndex).trim().replace('|', '').replace(/,/g, ' '));
                responseData.recipientName = null;
                responseData.topicName = null;
                if (responseData.exchange_recipient) {
                    responseData.recipientName = responseData.exchange_recipient.recipient;
                }
                if (responseData.exchange_topic) {
                    responseData.topicName = responseData.exchange_topic.topic;
                }
                setDefinition(responseData.definition);
                setInterpretation(responseData.interpretation);
                console.log(responseData.star_rating);
                setStarRating(responseData.star_rating);
                setExchange(responseData);
                console.log(responseData);
            }
            catch (error) {
                console.error('Error fetching exchange data:', error);
            }
        });
        fetchExchange();
    }, [id]);
    // Handle editing of definitions and interpretations
    const handleInterpretation = () => __awaiter(void 0, void 0, void 0, function* () {
        setInterpretationStatus('Sending...');
        axios.post(`/api/line/exchange/${id}/update`, { interpretation: interpretation })
            .then(res => {
            setSentInterpretation(true);
        });
    });
    const handleDefinition = () => __awaiter(void 0, void 0, void 0, function* () {
        setDefinitionStatus('Sending...');
        axios.post(`/api/line/exchange/${id}/update`, { definition: definition })
            .then(res => {
            setSentDefinition(true);
        });
    });
    // Handle star rating changes
    const handleStarRatingChange = (rating) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield axios.post(`/api/exchange/${id}/update`, { starRating: rating });
            setExchange((prev) => (prev ? Object.assign(Object.assign({}, prev), { starRating: rating }) : null));
        }
        catch (error) {
            console.error('Error updating star rating:', error);
        }
    });
    const toggleTwoStars = () => __awaiter(void 0, void 0, void 0, function* () {
        if (starRating === 'two') {
            setStarRating(null);
            axios.post(`/api/line/exchange/${id}/update`, { star_rating: null });
        }
        else {
            setStarRating('two');
            axios.post(`/api/line/exchange/${id}/update`, { star_rating: 'two' });
        }
    });
    const toggleThreeStars = () => __awaiter(void 0, void 0, void 0, function* () {
        if (starRating === 'three') {
            setStarRating(null);
            axios.post(`/api/line/exchange/${id}/update`, { star_rating: null });
        }
        else {
            setStarRating('three');
            axios.post(`/api/line/exchange/${id}/update`, { star_rating: 'three' });
        }
    });
    if (!exchange)
        return React.createElement("div", null, "Loading...");
    return (React.createElement("div", { className: 'container px-0' },
        React.createElement(Header, null),
        React.createElement("div", { className: 'd-flex flex-column px-3', style: { 'height': '563px' } },
            React.createElement("div", { style: { 'paddingBottom': '226px' } },
                React.createElement("div", { className: 'card border-secondary mb-3' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("h5", null, "Transmission"),
                        React.createElement("p", { className: 'mb-0' }, exchange.transmission))),
                React.createElement("div", { className: 'card border-success' },
                    React.createElement("div", { className: 'card-body' },
                        React.createElement("h5", null, "Response"),
                        React.createElement("p", { className: 'mb-0 text-success mb-2' }, exchange.response),
                        React.createElement("div", { className: 'text-success', style: { 'fontFamily': 'monospace', 'fontSize': '12px' } },
                            React.createElement("div", { className: 'text-muted' }, "1 2 3 4 5 6 7 8 9 0"),
                            React.createElement("div", null, exchange.responseNumbers)),
                        interpretation !== null &&
                            React.createElement(React.Fragment, null,
                                React.createElement("p", { className: 'mb-0 mt-2 text-muted', style: { 'fontWeight': 500 } }, "Interpretation"),
                                React.createElement("p", { className: 'mb-0 text-muted' },
                                    React.createElement("i", null, interpretation))),
                        definition !== null &&
                            React.createElement(React.Fragment, null,
                                React.createElement("p", { className: 'mb-0 mt-2 text-muted', style: { 'fontWeight': 500 } }, "Definition"),
                                React.createElement("p", { className: 'mb-0 text-muted' },
                                    React.createElement("i", null, definition))),
                        exchange.topicName !== null &&
                            React.createElement(React.Fragment, null,
                                React.createElement("p", { className: 'mb-0 mt-2 text-muted', style: { 'fontWeight': 500 } }, "Topic"),
                                React.createElement("p", { className: 'mb-0 text-muted' },
                                    React.createElement("i", null, exchange.topicName))),
                        exchange.recipientName !== null &&
                            React.createElement(React.Fragment, null,
                                React.createElement("p", { className: 'mb-0 mt-2 text-muted', style: { 'fontWeight': 500 } }, "Recipient"),
                                React.createElement("p", { className: 'mb-0 text-muted' },
                                    React.createElement("i", null, exchange.recipientName))))),
                React.createElement("div", { className: 'd-flex align-items-center' },
                    React.createElement("button", { className: 'btn btn-link px-0 me-2 text-secondary', style: { textDecoration: 'none', color: 'inherit' }, onClick: () => showInterpretation ? setShowInterpretation(false) : setShowInterpretation(true) },
                        React.createElement(FontAwesomeIcon, { icon: faEdit }),
                        " Interpretation"),
                    React.createElement("button", { className: 'btn btn-link px-0 me-2 text-secondary', style: { textDecoration: 'none', color: 'inherit' }, onClick: () => showDefinition ? setShowDefinition(false) : setShowDefinition(true) },
                        React.createElement(FontAwesomeIcon, { icon: faEdit }),
                        " Definition"),
                    React.createElement("div", { className: 'text-secondary me-2', onClick: () => toggleTwoStars() }, starRating == 'two'
                        ?
                            React.createElement(React.Fragment, null,
                                React.createElement(FontAwesomeIcon, { icon: faStar }),
                                React.createElement(FontAwesomeIcon, { icon: faStar }))
                        :
                            React.createElement(React.Fragment, null,
                                React.createElement(FontAwesomeIcon, { icon: faStarOutline }),
                                React.createElement(FontAwesomeIcon, { icon: faStarOutline }))),
                    React.createElement("div", { className: 'text-secondary', onClick: () => toggleThreeStars() }, starRating == 'three'
                        ?
                            React.createElement(React.Fragment, null,
                                React.createElement(FontAwesomeIcon, { icon: faStar }),
                                React.createElement(FontAwesomeIcon, { icon: faStar }),
                                React.createElement(FontAwesomeIcon, { icon: faStar }))
                        :
                            React.createElement(React.Fragment, null,
                                React.createElement(FontAwesomeIcon, { icon: faStarOutline }),
                                React.createElement(FontAwesomeIcon, { icon: faStarOutline }),
                                React.createElement(FontAwesomeIcon, { icon: faStarOutline }))))),
            showInterpretation &&
                React.createElement(React.Fragment, null, sentInterpretation
                    ? React.createElement("div", { className: 'mt-auto pb-4' }, "Interpretation sent.")
                    : React.createElement("div", { className: 'p-3', style: { 'position': 'fixed', 'bottom': '0', 'left': '0', 'width': '100%', 'backgroundColor': 'white' } },
                        React.createElement("textarea", { className: "form-control mb-3", id: "interpretation", placeholder: 'Interpretation...', rows: "2", value: interpretation, onChange: e => setInterpretation(e.target.value) }),
                        React.createElement("button", { type: 'button', className: 'btn btn-light', onClick: handleInterpretation }, interpretationStatus))),
            showDefinition &&
                React.createElement(React.Fragment, null, sentDefinition
                    ? React.createElement("div", { className: 'mt-auto pb-4' }, "Definition sent.")
                    : React.createElement("div", { className: 'p-3', style: { 'position': 'fixed', 'bottom': '0', 'left': '0', 'width': '100%', 'backgroundColor': 'white' } },
                        React.createElement("textarea", { className: "form-control mb-3", id: "definition", placeholder: 'Definition...', rows: "2", value: definition, onChange: e => setDefinition(e.target.value) }),
                        React.createElement("button", { type: 'button', className: 'btn btn-light', onClick: handleDefinition }, definitionStatus))))));
};
export { ExchangeDetail };
