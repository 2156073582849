import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from 'client/components/Header';
import { faScaleBalanced } from '@fortawesome/free-solid-svg-icons';
const Laws = () => {
    const [laws, setLaws] = useState('');
    useEffect(() => {
        axios.get('/api/laws')
            .then(res => {
            console.log(res);
            const newLaws = res.data.map((law, index) => {
                return (React.createElement("div", { className: 'mb-3', key: index },
                    law.number,
                    " ",
                    law.law));
            });
            setLaws(newLaws);
        });
    }, []);
    return (React.createElement("div", { className: 'container px-0', style: { 'fontFamily': 'Inter' } },
        React.createElement(Header, { title: 'Laws', icon: faScaleBalanced }),
        React.createElement("div", { className: 'd-flex flex-column px-3' }, laws)));
};
export { Laws };
