import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from 'client/components/Header';
import ExchangeReadout from 'client/components/ExchangeReadout';
import { faBook } from '@fortawesome/free-solid-svg-icons';
const Tablet = () => {
    const [tablet, setTablet] = useState('');
    useEffect(() => {
        axios.get('/api/tablet')
            .then(res => {
            console.log(res);
            const newTablet = res.data.map((exchange, index) => {
                return (React.createElement("div", { className: 'mb-3', key: index },
                    React.createElement(ExchangeReadout, { exchange: exchange })));
            });
            setTablet(newTablet);
        });
    }, []);
    return (React.createElement("div", { className: 'container px-0', style: { 'fontFamily': 'Inter' } },
        React.createElement(Header, { title: 'Tablet', icon: faBook }),
        React.createElement("div", { className: 'd-flex flex-column px-3' }, tablet)));
};
export { Tablet };
